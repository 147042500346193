<template>
  <div id="app">
    <HelloWorld/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,body,#app {
  width: 100%;
  height: 100%;
}
ul,li,a,ol {
  list-style: none;
  text-decoration: none;
}
body { 
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}
a:focus,input:focus,p:focus,div:focus,img:focus { 
	-webkit-tap-highlight-color:rgba(0,0,0,0); 
	-webkit-user-modify:read-write-plaintext-only; 
}

</style>
