<template>
    <div class="spinner"></div>
  </template>
  
  <script>
  export default {
    name: 'LoadingSpinner'
  }
  </script>
  
  <style scoped>
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-top-color: #ffd60a;
    animation: spin 1s ease-in-out infinite;
    margin: 80px auto 0;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  </style>
  